export const tema = {
    light: {
        colorFont: '#0D1117',
        background: '#fff',
        contrastColor: '#7DA2DA',
        colorFontContrast: '#fff'
    },
    dark: {
        colorFont: '#fff',
        background: '#0D1117',
        contrastColor: '#7DA2DA',
        colorFontContrast: '#0D1117'
        

    }
}
